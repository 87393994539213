.skill-section {
    background: rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    border-radius: 25px;
    border: 1px solid var(--main-accent-color);
    color: white;
    height: 372px;
    padding: 46px;
    position: relative;
    overflow: hidden;
    width: 298px;
}

ul.skill-list {
    list-style: none;
}

ul.skill-list .skill-item {
    font-family: var(--body-font);
    font-style: normal;
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1em;
}

h2.skill-category-name {
    color: var(--main-accent-color);
    font-family: var(--main-font);
    font-weight: 800;
    font-size: 70px;
    bottom: 175px;
    right: -180px;
    opacity: .6;
    position: absolute;
    transform: rotate(-90deg);
    line-height: 0;
    width: 387px;
    height: 60px;
    text-align: left;
    z-index: 0;
}

@media only screen and (min-device-width: 300px) and (max-device-width: 812px) { 
    .skill-section {
        display: inline-block;
    }
}