.education .section-landing {
    color: white;
}

.education .education-list {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.education-item {
    background: rgba(0, 0, 0, 0.5);
    border: 1px solid var(--main-accent-color);
    border-radius: 15px;
    height: 161px;
    margin-top: 38px;
    width: 350px;
    overflow: hidden;
    position: relative;
}

.education-item .education-title {
    color: white;
    font-family: var(--body-font);
    font-size: 1.625rem;
    font-weight: 600;
    line-height: 35px;
    margin: 18px;
}

.education-item .education-info {
    align-items: center;
    background: var(--main-accent-color);
    bottom: 0;
    display: flex;
    flex-direction: row;
    font-family: var(--main-font);  
    justify-content: space-between;
    left: 0;
    position: absolute;
    height: 40px;
    width: 100%;
}

.education-item .education-info p {
    color: black;
    font-weight: 800;
    line-height: 19px;
}

.education-item .education-info p:nth-child(1) {
    margin-left: 20px;
    text-align: left;
}

.education-item .education-info p:nth-child(2) {
    margin-right: 20px;
    text-align: right;
}


/* Media Queries */
/* Extra Small */   
@media only screen and (max-width: 575px) {
    .education-item {
        width: 312px;
    }

    .education-item .education-title {
        font-size: 1.5rem;
        margin-left: 16px;
        margin-right: 16px;
        line-height: 30px;
    }

    .education-item .education-info p {
        font-size: 0.875rem;
    }
}

/* Small */
@media only screen and (min-width: 576px) and (max-width: 767px) {
    .education-item {
        width: 312px;
    }

    .education-item .education-title {
        font-size: 1.5rem;
        margin-left: 16px;
        margin-right: 16px;
        line-height: 30px;
    }

    .education-item .education-info p {
        font-size: 0.875rem;
    }
}

/* Medium */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .education-item {
        width: 312px;
    }

    .education-item .education-title {
        font-size: 1.5rem;
        margin-left: 16px;
        margin-right: 16px;
        line-height: 30px;
    }

    .education-item .education-info p {
        font-size: 0.875rem;
    }
}

/* Large */
@media only screen and (min-width: 992px) and (max-width: 1199px) { 
}

/* Extra Large */
@media only screen and (min-width: 1200px) and (max-width: 1399px) { 
}

/* XXL */
@media only screen and (min-width: 1400px) { 
}