.portfolio-modal-overlay {
    background-color: transparent;
    left: 0;
    height: 100vh;
    opacity: 1;
    overflow: auto;
    position: fixed;
    top: 0;
    width: 100%;    
    z-index: 5;
    transition: opacity 400ms ease-out;
}

.portfolio-modal-overlay.hidden-modal {
    opacity: 0;
    z-index: -10;
    transition: opacity 400ms ease-out;
}

.portfolio-modal {
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    min-height: 100%;
    position: relative;
}

.portfolio-modal .close-button {
    align-self: flex-end;
    background-color: transparent;
    border: none;
    color: black;
    cursor: pointer;
    font-size: 24px;
    outline: none;
    padding: 8px;
    text-align: center;
    text-decoration: underline;
}

.portfolio-modal .project-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.portfolio-modal .project-heading .project-icon {
    width: 40px;
}

.portfolio-modal .project-heading .project-name {
    color: black;
    font-family: var(--main-font);
    font-size: 1.625rem;
    font-weight: 800;
    line-height: 31px;
    margin-top: 15px;
}

/* Carousel */
.portfolio-modal .modal-main-carousel {
    align-self: flex-start;
    box-sizing: border-box;
    margin-top: 24px;
    padding: 16px;
    width: 100%;
}

.portfolio-modal .modal-main-carousel .wrapper {
    overflow: hidden;
    position: relative;
}

.portfolio-modal .modal-main-carousel .wrapper .items {
    position: relative;
}

.portfolio-modal .modal-main-carousel .wrapper .items.shifting {
    transition: left .2s ease-out;
}

.portfolio-modal .modal-main-carousel .project-screenshot {
    border: 1px solid black;
    float: left;
    margin-right: 8px;
    transition: all 1s;
    width: 130px;
    min-height: calc(130px * 1.9);
}

.portfolio-modal .modal-main-carousel .project-screenshot:last-child {
    margin-right: unset;
}
/* End Carousel */

.portfolio-modal .project-info {
    color: black;
    font-family: var(--main-font);
    font-size: 1rem;
    line-height: 19px;
    list-style-type: none;
    margin: 16px;
}

.portfolio-modal .project-info li {
    margin-bottom: 8px;
}

.portfolio-modal .project-info .project-link {
    color: red;
    text-decoration: underline;
}

@media only screen and (min-width: 992px) { 
    .portfolio-modal .modal-main-carousel .project-screenshot {
        width: 250px;
        min-height: calc(250px * 1.9);
    }
}