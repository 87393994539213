.portfolio {
    margin-top: 40px;
    min-height: 700px;
}

.portfolio-heading {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.portfolio-heading .main-heading {
    margin-top: 0px;
}

.portfolio-heading #cboProjectType {
    appearance: none;
    background: rgba(0, 0, 0, 0.5);
    border: 1px solid var(--main-accent-color);
    border-radius: 5.8px;
    color: var(--main-accent-color);
    display: none; /* Hidden until there are some personal projects to show */
    font-family: var(--main-font);
    font-style: italic;
    font-size: 1.25rem;
    height: 43px;
    margin-left: 20px;
    outline: none;
    padding: 8px;
    width: 120px;
}

.portfolio-item {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.portfolio-item-heading {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 16px;
    max-width: 300px;
    position: relative;
    padding: 8px;
}

.portfolio-item-heading::after {
    background: var(--main-accent-color);
    bottom: 0;
    content: " ";
    height: 2px;
    position: absolute;
    width: 100%;
    z-index: 1;
}

.portfolio-item-heading img {
    width: 22px;
}

.portfolio-item-heading h2 {
    color: white;
    font-family: var(--main-font);
    font-size: 1.25rem;
    margin-left: 8px;
}

.portfolio-item-screenshot-container {
    overflow: hidden;
    position: relative;
    width: 250px;
}

.portfolio-item-screenshot-container > .screenshot {
    pointer-events: none;
    width: 100%;
}

.portfolio-item-screenshot-container > .platform {
    border: 1px solid var(--main-accent-color);
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
}

/* Media Queries */
/* Extra Small */
@media only screen and (max-width: 575px) {
    .portfolio-heading { 
        align-items: flex-end;
        justify-content: space-between;
    }

    .portfolio-heading #cboProjectType {
        margin-left: unset;
        margin-right: 12px;
        font-size: 1rem;
        padding: 8px;
        width: 105px;
    }
}

/* Small */
@media only screen and (min-width: 576px) and (max-width: 767px) {
}

/* Medium */
@media only screen and (min-width: 768px) and (max-width: 991px) { 
}

/* Large */
@media only screen and (min-width: 992px) and (max-width: 1199px) { 
}

/* Extra Large */
@media only screen and (min-width: 1200px) and (max-width: 1399px) { 
}

/* XXL */
@media only screen and (min-width: 1400px) { 
}