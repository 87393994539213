header {
    display: flex;
    justify-content: flex-end;
}

.mobile-header {
    display: none;
}

header nav {
    margin-right: 16px;
    margin-top: 8px;
}

header nav li {
    display: inline-block;
    padding: 8px;
}

nav li a.header-item {
    color: white;
    text-decoration: none;
    font-family: var(--main-font);
    font-weight: 400;
    font-size: 22pt;
}

nav li a.header-item.active {
    color: var(--main-accent-color);
}

.about-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-picture-container {
    border: 2px solid white;
    box-shadow: 0px 0px 4px 3px var(--main-accent-color);
    align-items: center;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    height: 300px;
    overflow: hidden;
    position: relative;
    margin-top: 150px;
    width: 300px;
    z-index: 2;
}

.profile-picture-container .blurred-background {
    /* aspect-ratio: 1/1; */
    background: url('../../assets/img/profilePicture.webp');
    background-size: cover;
    background-position: top center;
    filter: blur(8px);
    min-height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 0;
}

.profile-picture-container .main-picture {
    width: 75%;
    z-index: 1;
}

.about-description {
    color: white;
    font-family: var(--main-font);
    line-height: 60px;
    margin: 110px 32px;
    margin-bottom: 32px;
}

.about-description, .about-description span {
    font-size: 2.25rem;
}

@media only screen and (min-device-width: 300px) and (max-device-width: 812px) { 
    header nav {
        display: none;
    }

    .profile-picture-container {
        height: 200px;
        width: 200px;
    }

    .profile-picture-container .blurred-background {
        height: auto;
    }

    .about-description, .about-description span  {
        font-size: 1.25rem;
        line-height: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .mobile-header {
        align-items: flex-end;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        height: 60px;
        left: 0;
        padding: 16px;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 3;
        transition: 200ms;
    }

    .mobile-header.offset-y-changed {
        background: rgba(0, 0, 0, 0.6);
        border-bottom: 1px solid var(--main-accent-color);
        transition: 200ms;
    }

    .mobile-header.active {
        background: rgba(0, 0, 0, 0.6);
        height: 100vh;
        transition: 200ms;
    }

    .mobile-header .blur {
        backdrop-filter: blur(4px);
        height: calc(100% - 1px);
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .burger-button {
        align-items: center;
        background: transparent;
        border: none;
        display: flex;
        flex-direction: column;
        outline: none;
        z-index: 4;
    }

    .burger-button .burger-line {
        background: var(--main-accent-color);
        border-radius: 1px;
        display: block;
        height: 5px;
        margin-bottom: 4px;
        pointer-events: none;
        user-select: none;
        width: 30px;
        transition: 200ms;
    }

    .burger-button.active {
        align-items: center;
        justify-content: center;
    }

    .burger-button.active .burger-line:nth-child(even) {
        opacity: 0;
    }

    .burger-button.active .burger-line:nth-child(1) {
        transform-origin: center center;
        transform: rotate(45deg) translate(12px);
        transition: 200ms;
    }

    .burger-button.active .burger-line:nth-child(3) {
        transform-origin: center center;
        transform: rotate(-45deg) translate(12.5px);
        transition: 200ms;
    }

    .mobile-header .mobile-header-options {
        background-color: transparent;
        text-align: right;
        margin-top: 16px;
        list-style-type: none;
        z-index: 4;
    }

    .mobile-header .mobile-header-options li {
        padding: 6px;
    }

    .mobile-header .mobile-header-options a {
        color: var(--main-accent-color);
        font-family: var(--main-font);
        font-weight: bold;
        font-size: 1.875rem;
        line-height: 40px;
        text-decoration: none;
    }
}