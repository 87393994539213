.skills {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 65%;
}

.skills-group {
    align-content: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-top: 4em;
}

.rec.rec-dot {
    background-color: transparent;
    border: 2px solid var(--main-accent-color);
    box-shadow: unset;
    height: 12px;
    width: 12px;
}

.rec.rec-dot_active {
    background-color: var(--main-accent-color);
}

@media only screen and (max-width: 414px) { 
    .skills {
        margin: unset;
        width: 100%;
    }
}

@media only screen and (min-width: 415px) and (max-width: 729px) { 
    .skills {
        margin: 0 auto;
        width: 80%;
    }
}

@media only screen and (min-width: 730px) and (max-width: 1139px) { 
    .skills {
        width: 90%;
    }
}

@media only screen and (min-width: 1140px) {
    .skills {
        margin: 0 auto;
        width: 65%;
    }
}