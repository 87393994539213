.experience {
    color: white;
    margin-top: 20px;
}

.experience-item {
    display: flex;
    flex-direction: column;
    margin-top: 36px;
    margin-left: 12%;
    margin-right: 20%;
    position: relative;
}

.experience-item .date-interval {
    left: -200px; /* 200 + Margin right from design */
    margin-top: 40px;
    min-width: 153px;
    position: absolute;
    transform: rotate(-90deg);
    transform-origin: top right;
}

.experience-item .date-interval p {
    color: var(--main-accent-color);
    font-family: var(--main-font);
    font-size: 1.375rem;
    font-weight: bold;
    line-height: 26px;
    text-align: right;
}

.experience-item .experience-info {
    display: flex;
    flex-direction: column;
}

.experience-item .experience-info .charge {
    font-family: var(--main-font);
    font-weight: 800;
    font-size: 1.875rem;
    line-height: 2.18rem;
}

.experience-item .experience-info .charge::after {
    content: " ";
    display: block;
    background-color: var(--main-accent-color);
    height: 2px;
    margin-top: 6px;
    width: 283px;
}

.experience-item .experience-info .company-name {
    font-family: var(--body-font);
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 35px;
    margin-top: 6px;
}

.experience-item .experience-info .job-description {
    font-family: var(--body-font);
    font-weight: normal;
    font-size: 1.375rem;
    line-height: 35px;
    margin-top: 6px;
}


/* Media Queries */
/* Extra Small */
@media only screen and (max-width: 575px) {
    .experience-item .date-interval p {
        font-size: 1.125rem;
        line-height: unset;
    }

    .experience-item .experience-info .charge {
        font-size: 1.625rem;
    }

    .experience-item .experience-info .company-name {
        font-size: 1.25rem;
        line-height: 1.43rem;
    }

    .experience-item .experience-info .job-description {
        font-size: 0.875rem;
        line-height: 1rem;
    }

    .experience-item .date-interval {
        left: -190px;
    }
}

/* Small */
@media only screen and (min-width: 576px) and (max-width: 767px) { 
    .experience-item .date-interval p {
        font-size: 1.125rem;
        line-height: unset;
    }

    .experience-item .experience-info .charge {
        font-size: 1.625rem;
    }

    .experience-item .experience-info .company-name {
        font-size: 1.25rem;
        line-height: 1.43rem;
    }

    .experience-item .experience-info .job-description {
        font-size: 0.875rem;
        line-height: 1rem;
    }

    .experience-item .date-interval {
        left: -190px;
    }
}

/* Medium */
@media only screen and (min-width: 768px) and (max-width: 991px) { 
    .experience-item .date-interval p {
        font-size: 1.125rem;
        line-height: unset;
    }

    .experience-item .experience-info .charge {
        font-size: 1.625rem;
    }

    .experience-item .experience-info .company-name {
        font-size: 1.25rem;
        line-height: 1.43rem;
    }

    .experience-item .experience-info .job-description {
        font-size: 0.875rem;
        line-height: 1rem;
    }

    .experience-item .date-interval {
        left: -190px;
    }
}

/* Large */
@media only screen and (min-width: 992px) and (max-width: 1199px) { 
}

/* Extra Large */
@media only screen and (min-width: 1200px) and (max-width: 1399px) { 
}

/* XXL */
@media only screen and (min-width: 1400px) { 
}