@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&family=Raleway:wght@400;600;700&display=swap');

* {
    font-size: 16px;
    margin: 0;
    padding: 0;
}

:root {
    /* Fonts */
    --main-font: 'Lato', sans-serif;
    --body-font: 'Raleway', sans-serif;

    /* Colors */
    --main-accent-color: #F0DB4F;
}

.hidden {
    display: none !important;
}

.nonscroll {
    overflow: hidden;
}

.main-background {
    background: url('../img/background.webp');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    position: absolute;
    left: 0;
    width: 100%;
    overflow: hidden;
    top: 0;
    z-index: -1;
}

.main-background .blur {
    backdrop-filter: blur(8px);
    height: 100%;
    width: 100%;
}

.highlighted-italic-text {
    color: var(--main-accent-color);
    font-family: var(--main-font);
    font-weight: bold;
    font-style: italic;
}

.main-heading {
    color: var(--main-accent-color);
    font-family: var(--main-font);
    font-size: 4em;
    margin-left: 32px;
    margin-top: 40px;
}

.section-landing {
    background-color: rgba(0, 0, 0, 0.5);
    border: 1px solid var(--main-accent-color);
    border-radius: 8px;
    box-sizing: border-box;
    font-family: var(--main-font);
    line-height: 60px;
    margin-top: 40px;
    margin-right: 32px;
    text-align: center;
    padding: 0px 15px
}

.section-landing a {
    text-decoration: none;
}

.section-landing, .highlighted-italic-text {
    font-size: 2.25rem;
}

.section-landing-wrapper {
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
}

/* Media Queries */
/* Extra Small */
@media only screen and (max-width: 575px) {
    .main-heading {
        font-size: 3rem; /* 48px */
        margin-left: 16px;
    }

    .section-landing {
        margin-right: 12px;
    }

    .section-landing, .highlighted-italic-text {
        font-size: 1.25rem;
    }
}

/* Small */
@media only screen and (min-width: 576px) and (max-width: 767px) { 
    .main-heading {
        font-size: 3rem; /* 48px */
        margin-left: 16px;
    }

    .section-landing {
        margin-right: 12px;
    }

    .section-landing, .highlighted-italic-text {
        font-size: 1.25rem;
    }
}

/* Medium */
@media only screen and (min-width: 768px) and (max-width: 991px) { 
    .main-heading {
        font-size: 3rem; /* 48px */
    }

    .section-landing {
        margin-right: 12px;
    }

    .section-landing, .highlighted-italic-text {
        font-size: 1.25rem;
    }
}

/* Large */
@media only screen and (min-width: 992px) and (max-width: 1199px) { 
}

/* Extra Large */
@media only screen and (min-width: 1200px) and (max-width: 1399px) { 
}

/* XXL */
@media only screen and (min-width: 1400px) { 
}