footer {
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.5);
    padding-top: 1px;
    margin-top: 32px;
}

footer .reach-links {
    color: white;
    font-family: var(--main-font);
    line-height: 33px;
    margin: 40px 32px;
}

.reach-links, .reach-links .highlighted-italic-text {
    font-size: 1.75rem;
}

footer .reach-links a {
    text-decoration: none;
}

footer .social-links {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    background: var(--main-accent-color);
    height: 50px;
}


footer .social-links img {
    margin-right: 10px;
    width: 25px;
}

footer .social-links .spacer {
    width: 120px;
}

footer .social-links .signature {
    font-family: var(--main-font);
    font-weight: bold;
    font-size: 1rem;
}

footer .social-links .signature-name {
    font-weight: 900;
}

/* Media Queries */
/* Extra Small */
@media only screen and (max-width: 575px) {
    footer .reach-links {
        line-height: 19px;
        margin: 32px;
    }

    .reach-links, .reach-links .highlighted-italic-text {
        font-size: 1rem;
    }

    footer .social-links {
        padding-left: 10px;
        padding-right: 10px;
        justify-content: space-between;
    }

    footer .social-links img {
        margin-right: 8px;
        width: 20px;
    }

    footer .social-links .spacer {
        width: 0px;
    }

    footer .social-links .signature, 
    footer .social-links .signature-name {
        font-size: 0.875rem;
    }
}

/* Small */
@media only screen and (min-width: 576px) and (max-width: 767px) {
    footer .reach-links {
        line-height: 19px;
        margin: 32px;
    }

    .reach-links, .reach-links .highlighted-italic-text {
        font-size: 1rem;
    }

    footer .social-links {
        padding-left: 10px;
        padding-right: 10px;
        justify-content: space-between;
    }

    footer .social-links img {
        margin-right: 8px;
        width: 20px;
    }

    footer .social-links .spacer {
        width: 0px;
    }

    footer .social-links .signature, 
    footer .social-links .signature-name {
        font-size: 0.875rem;
    } 
}

/* Medium */
@media only screen and (min-width: 768px) and (max-width: 991px) { 
}

/* Large */
@media only screen and (min-width: 992px) and (max-width: 1199px) { 
}

/* Extra Large */
@media only screen and (min-width: 1200px) and (max-width: 1399px) { 
}

/* XXL */
@media only screen and (min-width: 1400px) { 
}